import React from 'react';

// Components
import BlogRelatedPosts from '../components/blog-related-posts.js';
import SocialSharing from '../components/social-sharing.js';
import Meta from '../components/meta.js';

import { getBlogPost } from './blog/blog-posts.js';

class BlogPost extends React.Component {
  render() {
    const postPath = this.props.location.pathname;
    const post = getBlogPost(
      postPath.endsWith('/') ? postPath.slice(0, -1) : postPath
    );
    const alt = `voltaware blog ${post.title}`;

    return (
      <div id="blog-post">
        <Meta
          title={
            post.metaTitle ?
              `${post.metaTitle} | Voltaware` :
              "Voltaware Energy Blog"
          }
          description={post.metaDescription || "Keep up to date on the latest news at Voltaware."}
          url="https://www.voltaware.com/blog"
          author={post.author}
          keywords={post.metaKeywords}
        />

        <section className="section container">
          <article>
            <h1>{post.title}</h1>
            {post.img && (
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <img
                  src={post.img}
                  alt={alt}
                  title={post.title}
                  className="img-responsive"
                />
              </div>
            )}
            {!post.hideDescriptionOnView && (post.description || post.preview) && <h3
              dangerouslySetInnerHTML={{
                __html: post.description || post.preview,
              }}
              className="description"
            ></h3>}
            <div className="posted">
              Posted by {post.author} {post.date}
              <SocialSharing />
            </div>
            {post.text.map((text, i) => (
              <p key={i} dangerouslySetInnerHTML={{ __html: text }} />
            ))}
            <div className="tags">
              {post.tags.map((tag, i) => (
                <span key={i}>{tag.name}</span>
              ))}
            </div>
          </article>
          {post.related.length > 0 && <BlogRelatedPosts posts={post.related} />}
        </section>
      </div>
    );
  }
}

export default BlogPost;
