import React from 'react';
import { Helmet } from 'react-helmet';

const Meta = ({ title, category, description, url, image, redirected }) => {
  let newImage = image
    ? image
    : 'https://www.voltaware.com/img/voltaware-energy-monitor.svg';

  const canonicalUrl = url.replace('www.', '');

  return (
    <Helmet>
      {title && <title>{title}</title>}

      <meta charset="utf-8" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1,
        maximum-scale=2"
      />
      <meta name="googlebot" content={redirected ? "noindex, nofollow" : "index, follow"} />
      <meta name="robots" content={redirected ? "noindex, nofollow, noodp" : "index, follow, noodp"} />
      <meta name="theme-color" content="#3C3A39" />
      <meta name="author" content="Voltaware" />
      <meta name="contact" content="" />
      <meta
        name="google-site-verification"
        content="6n4Qs41z43nJTtJBRpOvVA0NYjVgZECNqLxYCvycTvM"
      />
      <meta name="coverage" content="Worldwide" />
      <meta name="mobile-web-app-capable" content="yes" />

      <meta property="og:locale" contqent="en_US" />
      <meta property="og:site_name" content="Voltaware" />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={url} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={newImage} />

      <meta name="description" content={description} />
      <meta name="category" content={category} />
      <meta itemprop="name" content={title} />
      <meta itemprop="description" content={description} />
      <meta itemprop="image" content={newImage} />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={newImage} />
      <meta name="twitter:title" content={`${title}`} />
      <meta name="twitter:url" content={url} />
      <meta name="twitter:site" content="@voltaware" />

      <link rel="canonical" href={canonicalUrl} />
    </Helmet>
  );
};

export default Meta;
